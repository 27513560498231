.design-your-pallet-container {
    padding: 2rem; 

    .step-1-heading {
        text-align: center; 
        font-size: 2.4rem;
        margin-top: 1rem; 
    }

    .input-wrapper {
        display: flex; 
        margin: 0 auto; 
        width: 12%; 
        align-items: center;
        font-size: .8rem; 
        margin-top: .5rem;

        .form-label {
            margin: 0 auto;
            text-align: center;
        }
    }

    .runner-specifications {
        font-size: 2rem; 
        margin-top: 3rem;
        display: flex;
        align-items: center;

        .question-icon {
            margin-left: 1.5rem; 
            width: 5%; 
        }
        
    }

    .line-1 {
        display: flex; 
        margin: 0 auto;
        width: 100%; 
        justify-content: space-between;
        margin-top: 3rem;

        .line-1-input {
            width: 30%; 

            .form-label {
                font-weight: bold; 
                font-size: 1rem; 
                margin-top: 1.5rem; 
                margin-bottom: 1.5rem; 
            }

            .form-input {
                width: 100%; 
                height: 50px;
                background: #EEEEEE; 
                text-decoration: none;
                border: none; 
                border-radius: 5px;
                margin-top: .5rem; 
                font-size: 1.6rem; 
                padding-left: .5rem; 
            }
        }
    }

    .line-2 {
        display: flex; 
        margin: 0 auto;
        width: 100%; 
        justify-content: space-between;
        margin-top: 1.5rem;

        .line-2-input {
            width: 48%; 

            .form-label {
                font-weight: bold; 
                font-size: 1rem; 
                margin-top: 1.5rem; 
                margin-bottom: 1.5rem; 
            }

            .form-input {
                width: 100%; 
                height: 50px;
                background: #EEEEEE; 
                text-decoration: none;
                border: none; 
                border-radius: 5px;
                margin-top: .5rem; 
                font-size: 1.6rem; 
                padding-left: .5rem; 
                margin-bottom: 1.5rem;
            }
        }
    }

    .bottom-container {
        display: flex; 

        .line-3-line-4-container {
            width: 50%; 

            .required-pallet-certifications-container {
                
                .form-label {
                    font-weight: bold; 
                    font-size: 1rem; 
                    margin-top: 3rem; 
                }

                .form-input {
                    width: 96%; 
                    height: 50px;
                    background: #EEEEEE; 
                    text-decoration: none;
                    border: none; 
                    border-radius: 5px;
                    margin-top: .5rem; 
                    font-size: 1.6rem; 
                    padding-left: .5rem; 
                    margin-bottom: 1.5rem;
                }
            }

            .special-notes-container {
                
                .form-label {
                    font-weight: bold; 
                    font-size: 1rem; 
                    margin-top: 3rem; 
                }

                .form-input {
                    width: 93%; 
                    height: 150px; 
                    background: #EEEEEE; 
                    text-decoration: none;
                    border: none; 
                    border-radius: 5px;
                    margin-top: .5rem; 
                    font-size: 1.6rem; 
                    padding-left: .5rem; 
                }
            }
        }
    

        img {
            width: 50%; 
        }

    }

    .deck-board-specifications {
        margin-top: 6rem;
    }

    .line-5 {
        display: flex; 
        margin: 0 auto;
        width: 100%; 
        justify-content: space-between;
        margin-top: 3rem;

        .line-5-input { 
            width: 30%; 

            .form-label {
                font-weight: bold; 
                font-size: 1rem; 
                margin-top: 1.5rem; 
                margin-bottom: 1.5rem; 
            }

            .form-input {
                width: 100%; 
                height: 50px;
                background: #EEEEEE; 
                text-decoration: none;
                border: none; 
                border-radius: 5px;
                margin-top: .5rem; 
                font-size: 1.6rem; 
                padding-left: .5rem; 
                margin-bottom: 1.5rem;
            }
        }
    }

    .button-container {
        display: flex;
        margin-bottom: 10rem;

        .next-step {
            margin: 0 auto; 
            text-align: center;
            width: 33%; 
            text-decoration: none;
            background: #5184DF; 
            color: white; 
            font-size: 1.4rem; 
            font-weight: bold; 
            height: 50px; 
            border: none; 
            border-radius: 5px; 
            margin-top: 2rem; 
            cursor: pointer;
        }
    }

    #skip { 
        margin-bottom: 0; 
    }


}


.type-of-divider {
    margin-top: 1.5rem;

    .form-label {
        font-weight: bold; 
        font-size: 1rem; 
        margin-top: 1.5rem; 
        margin-bottom: 3rem; 
    }

    .form-input {
        width: 40%; 
        height: 50px;
        background: #EEEEEE; 
        text-decoration: none;
        border: none; 
        border-radius: 5px;
        margin: .5rem 0 1.5rem 0; 
        font-size: 1.6rem; 
        padding-left: .5rem; 
    }
}