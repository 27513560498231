.signup-container {
    margin: 3rem auto 0 auto; 

    .signup-heading {
        text-align: center; 
        font-size: 2.4rem;
        margin-bottom: 3rem; 
    }

    .form-container {
        margin: 0 auto; 
        width: 60%; 
        max-width: 500px; 
            
        .form-label {
            font-weight: bold; 
            font-size: 1rem; 
            margin-top: 1.5rem; 
            margin-bottom: 1.5rem;   

            .form-input {
                width: 100%; 
                height: 50px;
                background: #EEEEEE; 
                text-decoration: none;
                border: none; 
                border-radius: 5px;
                margin-top: .5rem; 
                font-size: 1.6rem; 
                padding-left: .5rem; 
            }

            .being-edited {
                width: 100%; 
                height: 50px;
                background: #EEEEEE; 
                text-decoration: none;
                border: 2px solid #5184DF; 
                border-radius: 5px;
                margin-top: .5rem; 
                font-size: 1.6rem; 
                padding-left: .5rem; 
            }

            .required-error {
                margin-top: .5rem;
                color: red; 
                font-weight: 500; 
            }
        }

        .divider {
            height: 25px; 
        }

        .name-container {
            display: flex; 

            @media (max-width: 800px) {
                flex-direction: column;
            }

            .form-label {
                width: 50%; 

                @media (max-width: 800px) {
                    width: 100%; 
                    margin-top: 1.5rem; 
                }

                &:first-child {
                    margin-right: 2rem;
                    padding-right: -1rem;  
                }

            }

            #last-name {

                @media (max-width: 800px) {
                    margin-top: 0rem; 
                }
            }

        }

        // .under-input {
        //     display: flex; 
        //     flex-direction: row;
        //     justify-content: space-between;
        //     margin-top: .5rem; 
        //     font-size: .8rem; 

        //     .remember-me-container {
        //         display: flex; 
        //         // justify-content: space-between;
        //         align-items: center;

        //         .remember-me-label {
        //             display: flex; 
        //             font-size: .8rem; 
        //             align-items: center;
        //         }
        //     }

        //     .forgot-password {
        //         margin-top: .2rem; 
        //     }
        // }

        .messages {
            text-align: center; 
            font-size: 1rem; 
            color: red;
            margin: 1.5rem auto -1.5rem; 
        }

        .messages-success {
            color: black; 
            font-weight: bold; 
        }

        .profile-btn-container {
            display: flex;
            justify-content: space-between;

            .signup-btn {

                width: 45%; 
                text-decoration: none;
                text-decoration: underline;
                color: black; 
                background: white; 
                font-size: 1.4rem; 
                font-weight: bold; 
                height: 50px; 
                border: none; 
                border-radius: 5px; 
                margin-top: 2rem; 
                cursor: pointer;
                // &:disabled {
                //     background: #CCCCCC; 
                //     color: white; 
                // }
            }

            .edit-btn {
                width: 45%; 
                text-decoration: none;
                background: #5184DF; 
                color: white; 
                font-size: 1.4rem; 
                font-weight: bold; 
                height: 50px; 
                border: none; 
                border-radius: 5px; 
                margin-top: 2rem; 
                cursor: pointer;
            }
        }


        .create-an-account {
            text-align: center; 
            font-size: 1.2rem; 
            margin-top: 1rem; 
            cursor: pointer;
        }

    }
}