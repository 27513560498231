.start-building-container {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto; 
    margin-top: 3rem;
    
    h1 {
        text-align: center; 
        font-size: 2.4rem;            
    }

    .messages {
        text-align: center; 
        font-size: 1rem; 
        color: red;
        margin: 1.5rem auto -1.5rem; 
    }

    .messages-success {
        color: black; 
        font-weight: bold; 
    }

    button {
        margin: 0 auto; 
        text-align: center;
        width: 33%; 
        text-decoration: none;
        background: #5184DF; 
        color: white; 
        font-size: 1.4rem; 
        font-weight: bold; 
        height: 50px; 
        border: none; 
        border-radius: 5px; 
        margin-top: 2rem; 
        cursor: pointer;
    }
}