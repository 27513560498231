.topnav-wrapper {
    display: flex;
    height: 50px; 
    justify-content: center; 
    align-items: center;

    @media (max-width: 850px) {
        display: none; 
    }

    .topnav-links {
        text-decoration: none; 
        margin: 1rem 1.25rem; 
        color: #666666; 
        font-size: 1.4rem; 
    }
}