.app-main-background {
    width: calc(100% - 20px);  
    // height: calc(100% - 3rem); 
    height: 100%; 
    // min-height: 100%; 

    margin: 10px; 
    margin-top: 0; 
    background: white; 

    @media (max-width: 850px) {
        height: 100%; 
        // min-height: 100%; 
        margin-top: 10px; 
    }
}