.login-container {
    margin: 3rem auto 0 auto; 

    .login-heading {
        text-align: center; 
        font-size: 2.4rem;
        margin-bottom: 3rem; 
    }

    .form-container {
        margin: 0 auto; 
        width: 60%; 
        max-width: 500px; 
            
        .form-label {
            font-weight: bold; 
            font-size: 1rem; 
            margin-top: 10rem;
            margin-bottom: 5rem;  

            .form-input {
                width: 100%; 
                height: 50px;
                background: #EEEEEE; 
                text-decoration: none;
                border: none; 
                border-radius: 5px;
                margin-top: .5rem; 
                font-size: 1.6rem;
                padding-left: .5rem;  
            }

            .required-error {
                margin-top: .5rem;
                color: red; 
                font-weight: 500; 
            }
        }

        .divider {
            height: 25px; 
        }

        .under-input {
            display: flex; 
            flex-direction: row;
            justify-content: space-between;
            margin-top: .5rem; 
            font-size: .8rem; 

            @media (max-width: 600px) {
                flex-direction: column;
            }

            .remember-me-container {
                display: flex; 
                // justify-content: space-between;
                align-items: center;

                @media (max-width: 600px) {
                    justify-content: center;
                    margin-top: .5rem; 
                }

                .remember-me-label {
                    display: flex; 
                    font-size: .8rem; 
                    align-items: center;
                }
            }

            .forgot-password {
                margin-top: .2rem; 
                cursor: pointer;

                @media (max-width: 600px) {
                    text-align: center; 
                    margin-top: 1.5rem; 
                }
            }
        }

        .messages {
            text-align: center; 
            font-size: 1rem; 
            color: red;
            margin: 1.5rem auto -1.5rem; 
        }

        .messages-success {
            color: black; 
            font-weight: bold; 
        }

        .login-btn {
            width: calc(100% + .5rem); 
            text-decoration: none;
            background: #5184DF; 
            color: white; 
            font-size: 1.4rem; 
            font-weight: bold; 
            height: 50px; 
            border: none; 
            border-radius: 5px; 
            margin-top: 2rem; 
            cursor: pointer;

            @media (max-width: 600px) {
                margin-top: 1.5rem;    
            }

            &:disabled {
                background: #CCCCCC; 
                color: white; 
            }
        }

        .create-an-account {
            text-align: center; 
            font-size: 1.2rem; 
            margin-top: 1rem; 
            cursor: pointer;
        }

    }
}