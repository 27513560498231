.login-heading {
    text-align: center; 
    font-size: 2.4rem;
    margin: 3rem auto 3rem auto; 
}

form {
    margin: 3rem auto 0 auto; 
    margin: 0 auto; 
    width: 60%; 
    max-width: 500px; 

    .form-label {
        font-weight: bold; 
        font-size: 1rem; 
        margin-top: 1.5rem; 
        margin-bottom: 1.5rem;   

        .form-input {
            width: 100%; 
            height: 50px;
            background: #EEEEEE; 
            text-decoration: none;
            border: none; 
            border-radius: 5px;
            margin-top: .5rem; 
            font-size: 1.6rem; 
            padding-left: .5rem; 
        }

        .required-error {
            margin-top: .5rem;
            color: red; 
            font-weight: 500; 
        }
    }

    .login-btn {
        width: calc(100% + .5rem); 
        text-decoration: none;
        background: #5184DF; 
        color: white; 
        font-size: 1.4rem; 
        font-weight: bold; 
        height: 50px; 
        border: none; 
        border-radius: 5px; 
        margin-top: 2rem; 
        cursor: pointer;

        &:disabled {
            background: #CCCCCC; 
            color: white; 
        }
    }
}