.sidenav-wrapper {
    width: 25%; 
    padding: 0px 20px; 
    background: #5184DF;
    display: flex; 
    flex-direction: column;
    // min-height: 100vh;
    // height: auto;  
    height: calc(100vh - 57px); 

    @media (max-width: 850px) {
        flex-direction: row; 
        height: 75px; 
        width: 100%; 
    }

    .signout {
        text-decoration: underline; 
        margin: 0 auto; 
        text-align: center; 
        color: white; 
        font-size: 1.2rem; 
        margin-top: 1.5rem; 
        
        &:hover {
            cursor: pointer;
        }

        @media (max-width: 850px) {
            display: none; 
        }
    }

    .side-links {
        margin: 2rem 0 0 0;
        color: white; 
        text-decoration: none;
        font-size: 1.6rem; 

        @media (max-width: 850px) {
            margin: 1.5rem 2rem;
        }

        &:hover {
          text-decoration: underline;   
        }

        .link-desc {
            margin-top: .5rem; 
            font-size: .8rem;
            font-weight: 400; 

            @media (max-width: 850px) {
                display: none; 
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    .active-side-links {
        text-decoration: none;

        @media (max-width: 850px) {
            display: none; 
        }
    }
     
}
